import React from "react";
import Button from '@material-ui/core/Button';

class PromptDisplay extends React.Component {
  render() {
    const randomColor = this.props.displayColor();
    const html = document.documentElement;
    html.style.backgroundColor = randomColor;

    return (
      <div style={{ backgroundColor: "white", padding: '5px', textAlign: 'center' }} className="quotebox">
        <div
          className="fadeIn"
          key={Math.random()}
          style={{ color: randomColor }}
        >
          <h1 id="quote">{this.props.promptText}</h1>
          <h5 id="author">
            -{this.props.promptDate ? this.props.promptDate : "Unknown"}-
          </h5>
        </div>
        <Button variant="contained"
          id="previousprompt"
          onClick={this.props.handlePreviousClick}
        >
          Previous
        </Button>
        <Button variant="contained"
          id="randomprompt"
          onClick={this.props.handleRandomClick}
        >
          Random
        </Button>
        <Button variant="contained"
          id="nextprompt"
          onClick={this.props.handleNextClick}
        >
          Next
        </Button>
      </div>
    );
  }
}

export default PromptDisplay;