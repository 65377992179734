const storypieces = [
    "a photo of a teddy bear",
    "an unmarked grave",
    "a broken window",
    "a bicycle with a missing wheel",
    "a single sock, discarded in the mud",
    "a broken television",
    "a half-eaten cupcake",
    "a ripped pillow",
    "a paperback book with the first chapter missing",
    "a doll with its eyes missing",
    "a picture frame without a picture",
    "a pair of baby's shoes",
    "a pawned engagement ring",
    "a brass key",
    "a torn stocking",
    "an empty grave",
    "a buried shoebox",
    "a dollhouse hidden under a bush",
    "a photo with the face cut out of it",
    "a half-decomposed crayon drawing",
    "a chair with a sign: 'free for anyone who wants it'",
    "a dog's collar left in the garbage",
    "the body of a buried lego figure",
    "a car that had once been on fire",
    "a birthday balloon, stuck in a tree",
    "a recording of someone laughing",
    "initials carved into a tree trunk",
    "a misshapen ceramic elephant",
    "a witch's cauldron",
    "a broken headlamp of a car",
    "a discarded hospital wrist tag",
    "a broken robot",
    "an analog wall clock with a missing minute hand",
    "a dented wedding ring hung on a necklace",
    "a pocket watch stuck at 7:07",
    "a purple wig on the street",
    "a chipped coffee mug",
    "a crystal in a silk pouch",
    "a hairdryer with a foreign plug in the middle of Iowa",
    "A spoon with the image of Teddy Roosevelt etched into it",
    "a painting of a sad-looking cow in the snow",
    "a hedge, shaped to look like a lion",
    "a tennis ball, buried in the snow",
    "a quarantine mask, with one strap broken",
    "a glove with the ends of the fingers cut off",
    "a paint brush, with pink paint dried on the end",
    "a cat collar, kept in a drawer",
    "a magic wand"
]

export default storypieces;