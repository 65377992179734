import soon from '../../assets/images/soon.png';
import islandscover from '../../assets/images/islands_sm.jpg';

const abooks = [
  /*  {
        id: 1,
        title: "Untitled Ghost Story",
        subtitle: "",
        description: "Moving back to the Suffolk coast was always supposed to be about reconnecting with family. The ghost which haunts an abandoned holiday park, however, has other ideas.",
        blurb: null,
        coverArt: soon,
        coverTitle: "Untitled Ghost Story Cover",
        release: "",
        infopath: null,
        link: null
    },*/
    {
        id: 2,
        title: "The Last Wizard",
        subtitle: "",
        description: "A failed wizard encounters one last chance to save humanity, but first he needs to save himself.",
        blurb: null,
        coverArt: soon,
        coverTitle: "Last Wizard Cover",
        release: "",
        infopath: null,
        link: null
    },
    /*{
        id: 3,
        title: "Islands",
        subtitle: "",
        description: "A book of short stories exploring themes of isolation and facing adversity.",
        blurb: null,
        coverArt: islandscover,
        coverTitle: "Cover",
        release: "",
        infopath: null,
        link: null
    }*/
];

export default abooks;
