import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from './Copyright.js'
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <>
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Cyramic Publishing, Ltd
        </Typography>
        <Typography variant="subtitle1" align="center" color="textPrimary" component="p">

        </Typography>
        <Copyright />
      </footer>
    </>
  );
}




