import React from 'react';
import './App.css';
import ScrollToTop from './ScrollToTop.js'
import Gallery from './components/Gallery/Gallery.js';
import BookInfo from './components/BookInfo.js';
import Projects from './components/Projects.js';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { indigo } from '@material-ui/core/colors';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import NavBar from './components/NavBar.js';
import Toolbar from '@material-ui/core/Toolbar';

const themeDefault = createTheme({
    palette: {
        background: {
            default: '#222222'
        },
        text: {
            primary: "#444444",
            secondary: "#dddddd"
        },
    }
})

function App() {
  return (
    <Router basename="/" >
        <div className="App">
          <MuiThemeProvider theme={themeDefault}>
            <CssBaseline />
            <Toolbar>
              <NavBar />
            </Toolbar>
            <ScrollToTop />
            <Switch>
                <Route path="/" exact component={Gallery} />
                <Route path="/projects" exact component={Projects} />
                <Route path="/books/:booktitle" exact component={BookInfo} />
            </Switch>
          </MuiThemeProvider>
        </div>
    </Router>
  );
}

const theme = createTheme({
  status: {
    danger: indigo[900],
  },
});

export default App;
