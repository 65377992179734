import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Footer from './Footer.js';
import yabooks from './BookList/YaBooksDatabase';
import abooks from './BookList/ABooksDatabase';
import Image from 'material-ui-image'
import Parser from 'html-react-parser';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import LinkIcon from '@material-ui/icons/Link';

const styles = theme => ({
  root: {
    display: 'flex',
    textAlign: 'left',
    height: '100%',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      width: '100%',
      height: '100%',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  cover: {
    width: '400px',
    paddingBottom: '15px'
  },
  quotebox: {
    backgroundColor: "white",
    padding: '5px',
    textAlign: 'center',
    borderRadius:'5px'
  },
  purchaseLink: {
    minWidth: '250px',
  }
});

class BookInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        book: false,
        error: false,
        version: '',
    }
  }

  componentDidMount () {
    const books = abooks.concat(yabooks);
    if (this.props.match.params) {
        try {
            const thisbook = books.find(o => o.infopath === this.props.match.params.booktitle);
            this.setState({ book: { ...thisbook }, error: false })
        } catch (err) {
            console.log(err);
            this.setState({ error: true })
        }
    }
  }

  handleClick = (e) => {
    this.setState({version: e.target.value});
  }

  handleButton = (e) => {
    window.location.replace(this.state.version);
  }

  render() {
    const { classes } = this.props;
    console.log("STATE:");
    console.log(this.state);

    return (
    <>
      <AppBar position="relative">

      </AppBar>
      <main>
          <div className={classes.root}>
            { this.state.book && (
            <Container maxWidth="md">
                <div className={classes.quotebox}>
                <Grid container alignItems="center" direction="column" justify="center">
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h2" align="center" color="textPrimary" className={classes.cover}>
                            <Image src={this.state.book.coverArt} imageStyle={{textAlign: 'center'}} width="auto"/>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                            { this.state.book.title }
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="h1" variant="body1" align="left" color="textPrimary" gutterBottom>
                            { Parser(this.state.book.blurb) }
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {(this.state.book.link)  && (
                        <>
                            <Grid container direction="column" justify="center" spacing="1" alignItems="center">
                                <Grid item xs>
                                  Available to purchase:
                                </Grid>
                                <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor="select-book-placeholder-label">Select Version</InputLabel>
                                <Select
                                    onChange={this.handleClick}
                                    labelId="select-book-placeholder-label"
                                    value={this.state.version}
                                    label="Select Version">
                                {this.state.book.link.map(item => <MenuItem value={item.url}>{item.title}</MenuItem>)}
                                </Select>
                                </FormControl>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={this.handleButton}>Buy Now</Button>
                            </Grid>
                        </>
                        )}
                    </Grid>
                </Grid>
                </div>
            </Container>
            )}
          </div>
      </main>
      <Footer />
    </>
    )
  }
}

export default withStyles(styles)(BookInfo);
