import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PromptDisplay from './PromptDisplay';
import prompts from './PromptsDatabase.js';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    textAlign: 'left',
    height: '100%',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      width: '100%',
      height: '100%',
    },
  },
  quotebox: {
    backgroundColor: "white",
    padding: '5px',
    textAlign: 'center',
    borderRadius:'5px'
  }
}));

const getPrompt = (num) => {
    return prompts[num];
}

const randomColor
 = () => {
  const color = `rgb(
    ${Math.floor(Math.random() * 155)},
    ${Math.floor(Math.random() * 155)},
    ${Math.floor(Math.random() * 155)})`;
  return color;
}

export default function Prompts() {
  const [currentPrompt, setCurrentPrompt] = useState({
    text: prompts[prompts.length - 1].text,
    pdate: prompts[prompts.length - 1].pdate,
    num: prompts.length - 1,
  });

  const handleRandomClick = () => {
    let num = Math.floor(Math.random() * prompts.length);
    const generatePrompt = getPrompt(num);
    setCurrentPrompt({
        text: generatePrompt.text,
        pdate: generatePrompt.pdate,
        num: num,
    });
  };

  const handlePreviousClick = () => {
    let num = currentPrompt.num - 1;
    if (num < 0) {
      num = prompts.length - 1;
    }

    const generatePrompt = prompts[num];
    setCurrentPrompt({
      text: generatePrompt.text,
      pdate: generatePrompt.pdate,
      num: num,
    });
  };

  const handleNextClick = () => {
    let num = currentPrompt.num + 1;
    if (num > prompts.length - 1) {
      num = 0;
    }

    const generatePrompt = prompts[num];
    setCurrentPrompt({
      text: generatePrompt.text,
      pdate: generatePrompt.pdate,
      num: num,
    });
  };

  const classes = useStyles();

  return (
    <>
        <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
            Past Writing Prompts
        </Typography>
        <PromptDisplay
            displayColor={randomColor}
            handleRandomClick={handleRandomClick}
            handlePreviousClick={handlePreviousClick}
            handleNextClick={handleNextClick}
            promptText={currentPrompt.text}
            promptDate={currentPrompt.pdate}
        />
     </>
  );
}