import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import LinkIcon from '@material-ui/icons/Link';
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  descriptionText: {
    textAlign: 'justify',
    paddingBottom: '1em',
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '100%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  releaseText: {
    color: '#334499',
    fontWeight: 'bold'
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  purchaseLink: {
    minWidth: '250px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  }
}));

export default function BookList({title, listofbooks}) {
  const classes = useStyles();
  const [selectedBook, setSelectedBook] = React.useState('');

  const handleChange = (event) => {
    setSelectedBook(event.target.value);
  };
  return (
    <>
        <Typography component="h1" variant="h4" align="center" color="textSecondary" gutterBottom>
          {title}
        </Typography>
        <Grid container spacing={4}>
            {/* Begin MG / YA Books  */}
            {listofbooks.map((card) => (
              <Grid item key={card.id} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={card.coverArt}
                    title={card.coverTitle}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h4" component="h2">
                      {card.infopath ? (
                          <Link to={"/books/" + card.infopath}>
                            {card.title}
                          </Link>
                      ) : (
                          <>
                          {card.title}
                          </>
                      )}
                    </Typography>
                    <Typography gutterBottom variant="h6" component="h2">
                      {card.subtitle}
                    </Typography>
                    <Typography className={classes.descriptionText}>
                      {card.description}
                    </Typography>
                    <Typography className={classes.releaseText}>
                      {card.release}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {(card.link)  && (
                        <>
                            <Grid container direction="column" justify="center" spacing="1" alignItems="center">
                            <Grid item xs>
                              Available to purchase:
                            </Grid>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor="select-book-placeholder-label">Select Version</InputLabel>
                                <Select
                                    onChange={handleChange}
                                    labelId="select-book-placeholder-label"
                                    value={selectedBook}
                                    label="Select Version">
                                {card.link.map(item => <MenuItem value={item.url}>{item.title}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <Button
                                color="primary"
                                variant="contained"
                                component="a" href={selectedBook}>Buy Now</Button>
                            </Grid>
                        </>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
    </>
  );
}